<template>
  <CRow v-cloak>
    <CCol sm="12">
       <code>{{ selectedLog.data }}</code>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: "RawData",   
  props: {
    selectedLog: Object
  }
};
</script>

<style scoped lang="scss">
</style>
