<template>
  <div id="printArea">
    <CRow>
      <CCol sm="12">
        <CCard>
          <CRow>
            <CCol sm="4">
              <h4 class="text-uppercase">TBL Reading Data view</h4>
            </CCol>
            <CCol sm="8">
              <form v-on:submit.prevent="filterConsumption()">
                <CRow>
                  <CCol sm="3" class="pr-0">
                    <CInput
                        required
                        type="date"
                        v-model="params.start_date"
                    />
                  </CCol>
                  <CCol sm="3" class="pr-0">
                    <CInput
                        required
                        type="date"
                        v-model="params.end_date"
                    />
                  </CCol>

                  <CCol sm="2">
                    <button type="submit" class="btn btn-success w-100">Submit</button>
                  </CCol>
                  <CCol sm="4">
                    <button type="button" @click="transferReading()" class="btn btn-warning w-100">
                      Transfer Reading to MSSQL
                    </button>
                  </CCol>
                </CRow>
              </form>
            </CCol>
          </CRow>
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr class="text-center">
                <th class="font-weight-bold">Account No</th>
                <th class="font-weight-bold">WRCode</th>
                <th class="font-weight-bold">MTRWDTH</th>
                <th class="font-weight-bold">NBill</th>
                <th class="font-weight-bold">PrevRead</th>
                <th class="font-weight-bold">PrevDate</th>
                <th class="font-weight-bold">PresRead</th>
                <th class="font-weight-bold">PresDate</th>
                <th class="font-weight-bold">Consum</th>
                <th class="font-weight-bold">WaterBill</th>
                <th class="font-weight-bold">Excise</th>
                <th class="font-weight-bold">Total</th>
                <th class="font-weight-bold">RiCode</th>
                <th class="font-weight-bold">BatchNo</th>
                <th class="font-weight-bold">Status</th>
                <th class="font-weight-bold">EntBy</th>
                <th class="font-weight-bold">EntryDate</th>
                <th class="font-weight-bold">NE</th>
                <th class="font-weight-bold">MonthEndDate</th>
                <th class="font-weight-bold">EntryDate2</th>
              </tr>
              </thead>
              <tbody v-if="consumptions.length">
              <tr
                  class="text-center"
                  v-for="consumption in consumptions"
                  :key="consumption.id"
              >
                 <td>{{ consumption.ACCOUNT }}</td>
                 <td>{{ consumption.WRCode }}</td>
                 <td>{{ consumption.MTRWDTH }}</td>
                 <td>{{ consumption.NBill }}</td>
                 <td>{{ consumption.PrevRead }}</td>
                 <td>{{ consumption.PrevDate }}</td>
                 <td>{{ consumption.PresRead }}</td>
                 <td>{{ consumption.PresDate }}</td>
                 <td>{{ consumption.Consum }}</td>
                 <td>{{ consumption.WaterBill }}</td>
                 <td>{{ consumption.Excise }}</td>
                 <td>{{ consumption.Total }}</td>
                 <td>{{ consumption.RiCode }}</td>
                 <td>{{ consumption.BatchNo }}</td>
                 <td>{{ consumption.Status }}</td>
                 <td>{{ consumption.EntBy }}</td>
                 <td>{{ consumption.EntryDate }}</td>
                 <td>{{ consumption.NE }}</td>
                 <td>{{ consumption.MonthEndDate }}</td>
                 <td>{{ consumption.EntryDate2 }}</td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td colspan="4">
                  <h5 class="text-center">Data Not Available.</h5>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <CPagination
              v-if="consumptions.length"
              align="center"
              :pages="rows"
              :active-page.sync="params.currentPage"
              @update:activePage="handlePageChange"
          />
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";

export default {
  name: "consumptionsConsumptionsView",
  data: () => {
    return {
      params: {
        // month: moment().subtract(1, 'months').format('YYYY-MM-dd'),
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        // start_date: moment().clone().startOf('month').subtract(1, 'months').format('YYYY-MM-DD'),
        // end_date: moment().clone().endOf('month').subtract(1, 'months').format('YYYY-MM-DD'),
        month: '',
        currentPage: 1
      },
      isMobile: Boolean,
      isMobileOnly: Boolean,
      breadcrumbs: [{text: "Home", path: "/dashboard", isActive: false}, {
        text: "METER CONSUMPTIONS",
        path: "/meter-consumptions",
        isActive: false
      }, {text: "Reading Data View", path: "", isActive: true}]
    };
  },

  computed: {
    ...mapGetters("Meter", [
      "consumptions",
      "rows",
      "perPage",
      "consumptionObj",
      "isLoading",
    ]),
  },
  methods: {
    filterConsumption() {
      this.$store.dispatch("Meter/getTblReadingView", this.params);
    },

    transferReading() {
      this.$store.dispatch("Meter/transferReadingToMSSQL", this.params);
    },
    dateFormat(date) {
      return moment(date).format("DD-MM-YYYY, h:mm a");
    },
    handlePageChange(value) {
      this.params.currentPage = value;
      this.$store.dispatch("Meter/getTblReadingView", this.params);
    },
  },
  mounted() {
    this.params.meterId = this.$route.params.id;
    this.$store.dispatch("Meter/getTblReadingView", this.params);
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>

<style scoped>
.card {
  border: none;
  padding: 15px 15px 0;
}

</style>

<style lang="scss" scoped>
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }

  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }

    tbody {
      font-size: 13px;

      tr {
        color: #000;

        td {
          padding: 0.75rem 0 0.75rem 0.75rem;

          button {
            font-size: 13px;
          }
        }
      }
    }
  }
}
</style>
