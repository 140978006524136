<template>
  <div id="printArea">
    <CRow>
      <CCol sm="12">
        <CCard>
          <CRow>
            <CCol sm="4">
              <h4 class="text-uppercase">Meter Consumptions</h4>
            </CCol>
            <CCol sm="8">
              <form v-on:submit.prevent="filterConsumption()">
                <CRow>
                  <CCol sm="3" class="pr-0">
                    <CInput
                        required
                        type="date"
                        v-model="params.start_date"
                    />
                  </CCol>
                  <CCol sm="3" class="pr-0">
                    <CInput
                        required
                        type="date"
                        v-model="params.end_date"
                    />
                  </CCol>

                  <CCol sm="2">
                    <button type="submit" class="btn btn-success w-100">Submit</button>
                  </CCol>
                  <CCol sm="4">
                    <router-link class="btn btn-warning w-100" :to="{name:'TblReadingView'}">
                      TBL Reading data
                    </router-link>
                  </CCol>
                </CRow>
              </form>
            </CCol>
          </CRow>
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr class="text-center">
                <th class="font-weight-bold">SL</th>
                <TableColumn align="center" name="Account No" sort="customer_account" :position="params.sortOrder" :id="params.sortBy" @sort="changeSort" />
                <TableColumn align="center" name="Meter No" sort="meter_number" :position="params.sortOrder" :id="params.sortBy" @sort="changeSort" />
                <th class="font-weight-bold">Present Meter Reading</th>
                <th class="font-weight-bold">Present Reading Date</th>
                <th class="font-weight-bold">Details</th>
                <!--                <th class="font-weight-bold">Created At</th>-->
              </tr>
              </thead>
              <tbody v-if="consumptions.length">
              <tr
                  class="text-center"
                  v-for="(consumption, index) in consumptions"
                  :key="consumption.id"
              >
                <td scope="row">{{ meta.from + index }}</td>
                <td v-if="consumption.customer_account">
                  <router-link :to="`/consumers/${consumption.customer_account_id}`">
                    {{ consumption.customer_account.account_number }}
                  </router-link>
                </td>
                <td>
                  <router-link :to="'/meters/' + consumption.id">{{ consumption.meter_number }}</router-link>
                </td>
                <td>{{ consumption.meter_current_consumption && consumption.meter_current_consumption.water_unit }}</td>
                <td>{{ consumption.meter_current_consumption && consumption.meter_current_consumption.reading_date_time | dateTimeFormat }}</td>
                <td>
                  <router-link v-if="checkUsrPermissions(['Meter_all', 'Meter_consumption'])"
                               :to="'/meters/' + consumption.id + '/consumptions'">Details Consumption
                  </router-link>
                </td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td colspan="6">
                  <h5 class="text-center">Data Not Available.</h5>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <CPagination
              v-if="consumptions.length"
              align="center"
              :pages="rows"
              :active-page.sync="params.currentPage"
              @update:activePage="handlePageChange"
          />
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";
import TableColumn from "@/components/TableColumn.vue";

export default {
  name: "consumptionsConsumptionsView",
  components: {TableColumn},
  data: () => {
    return {
      params: {
        // month: moment().subtract(1, 'months').format('YYYY-MM-dd'),
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        // start_date: moment().clone().startOf('month').subtract(1, 'months').format('YYYY-MM-DD'),
        // end_date: moment().clone().endOf('month').subtract(1, 'months').format('YYYY-MM-DD'),
        month: '',
        currentPage: 1,
        sortBy: "id",
        sortOrder: "desc",
      },
      isMobile: Boolean,
      isMobileOnly: Boolean,
      breadcrumbs: [{text: "Home", path: "/dashboard", isActive: false}, {
        text: "Meters",
        path: "/meters",
        isActive: false
      }, {text: "Consumptions", path: "", isActive: true}]
    };
  },

  computed: {
    ...mapGetters("Meter", [
      "consumptions",
      "rows",
      "perPage",
      "meta",
      "consumptionObj",
      "isLoading",
    ]),
  },
  methods: {
    changeSort(event) {
      this.filter.sortBy = event[0];
      this.filter.sortOrder = event[1];
      this.$store.dispatch("Meter/getAllMeterConsumptions", this.params);
    },

    filterConsumption() {
      this.$router.replace({name: "MeterConsumptions", query: this.params});
      this.$store.dispatch("Meter/getAllMeterConsumptions", this.params);
    },

    transferReading() {
      this.$store.dispatch("Meter/transferReadingToMSSQL", this.params);
    },
    handlePageChange(value) {
      this.params.currentPage = value;
      this.$router.replace({name: "MeterConsumptions", query: this.params});
      this.$store.dispatch("Meter/getAllMeterConsumptions", this.params);
    },
  },
  mounted() {
    this.params.currentPage = this.$route.query.currentPage ? parseInt(this.$route.query.currentPage) : 1;
    this.params.start_date = this.$route.query.start_date ? this.$route.query.start_date : this.params.start_date;
    this.params.end_date = this.$route.query.end_date ? this.$route.query.end_date : this.params.end_date;
    this.params.meterId = this.$route.params.id;
    this.$router.replace({name: "MeterConsumptions", query: this.params});
    this.$store.dispatch("Meter/getAllMeterConsumptions", this.params);
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>

<style scoped>
.card {
  border: none;
  padding: 15px 15px 0;
}

</style>

<style lang="scss" scoped>
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }

  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }

    tbody {
      font-size: 13px;

      tr {
        color: #000;

        td {
          padding: 0.75rem 0 0.75rem 0.75rem;

          button {
            font-size: 13px;
          }
        }
      }
    }
  }
}
</style>
