<template>
  <div v-cloak>
    <CRow>
      <CCol :md="9" :sm="9" :xs="12">
        <h4 class="text-uppercase">Meter Error Logs</h4>
      </CCol>
    </CRow>

    <CRow>
      <CCol cols="12">
        <CCard>
          <CRow class="pb-4">
            <CCol :md="9" :sm="9" :xs="12">
              <h4 v-if="errorLog">
                Meter Serial No: {{ errorLog.meter_serial_no }}
              </h4>
            </CCol>
            <CCol :md="3" :sm="3" :xs="12" class="text-right">
              <select
                name="filter_type"
                v-model="filter.type"
                @change="getErrorLogs(filter)"
                class="form-control"
              >
                <option value="">Select Error Type</option>
                <option value="LOW_BATTERY">LOW BATTERY</option>
                <option value="LEAKAGE">LEAKAGE</option>
                <option value="PERMANENT">PERMANENT</option>
                <option value="MANIPULATION">MANIPULATION</option>
                <option value="DRY">DRY</option>
                <option value="BACKFLOW">BACKFLOW</option>
                <option value="BURST">BURST</option>
                <option value="DATA_DISCONTINUATION"
                  >DATA DISCONTINUATION</option
                >
              </select>
            </CCol>
          </CRow>

          <CRow>
            <CCol>
              <div class="table-responsive">
                <table class="table table-borderless">
                  <thead>
                    <tr class="text-center">
                      <th class="font-weight-bold">Error Time</th>
                      <th class="font-weight-bold">Type</th>
                      <th class="font-weight-bold">Port No</th>
                      <th class="font-weight-bold">Description</th>
                      <th class="font-weight-bold">Status</th>
                      <!-- <th class="font-weight-bold">Created At</th> -->
                      <th class="font-weight-bold" style="width: 25%">Actions</th>
                    </tr>
                  </thead>
                  <tbody v-if="errorLogs.length">
                    <tr
                      class="text-center"
                      v-for="log in errorLogs"
                      :key="log.id"
                    >
                      <td>{{ log.error_time | dateFormat }}</td>
                      <td>{{ log.type }}</td>
                      <td>{{ log.port_no }}</td>
                      <td>{{ log.description }}</td>
                      <td>
                        <span
                          class="badge p-2"
                          :class="
                            log.status === 'PENDING'
                              ? 'badge-danger'
                              : log.status === 'WORKING'
                              ? 'badge-warning'
                              : 'badge-success'
                          "
                          >{{ log.status }}</span
                        >
                        <p v-if="log.status === 'SOLVED'" class="m-0 text-warning font-weight-bold"><i>Solved at {{ log.solved_at | dateFormat }}</i></p>
                      </td>
                      <!-- <td style="width: 35%">
                    <code>{{ log.data }}</code>
                  </td> -->
                      <!-- <td>{{ dateFormat(log.created_at) }}</td> -->
                      <td>
                        <button
                          type="button"
                          class="btn btn-info btn-sm"
                          @click="showRawData(log)"
                          title="Display Raw Data"
                        >
                          Raw Data
                        </button>
                        &nbsp;&nbsp;
                        <router-link
                          :to="'/meters/' + log.meter_id"
                          class="btn btn-warning btn-sm"
                          >Locate Meter</router-link
                        >
&nbsp;&nbsp;
                        <CDropdown
                          color="primary"
                          toggler-text="Update Status"
                          class="cdropdown"
                          size="sm"
                          v-if="
                            log.type === 'DATA_DISCONTINUATION' &&
                              log.status !== 'SOLVED'
                          "
                        >
                          <CDropdownItem @click="updateStatus(log, 'WORKING')">Working</CDropdownItem>
                          <CDropdownItem @click="updateStatus(log, 'SOLVED')">Solved</CDropdownItem>
                        </CDropdown>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="6">
                        <h4 class="text-center">Data not available.</h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCol>
          </CRow>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Row Data"
      color="primary"
      class="hide-footer custom-close-button"
      :show.sync="show"
    >
      <RawData
        @closeRawDataModal="closeRawDataModal"
        :selectedLog="selectedLog"
      ></RawData>
    </CModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RawData from "./partial/RawData";
export default {
  name: "MeterErrors",
  components: {
    // Loading,
    RawData,
  },
  data() {
    return {
      // errorLogs: Array,
      // meterId: Number,
      // errorLog: Object,
      filter: {
        meterId: "",
        type: "",
      },
      // isLoading: false,
      selectedLog: {},
      show: false,
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Meters", path:"/meters", isActive: false}, {text:"Error Logs", path:"", isActive: true}]
    };
  },
  computed: {
    ...mapGetters("Meter", ["errorLogs", "errorLog"]),
  },
  methods: {
    getErrorLogs(filter) {
      this.$store.dispatch("Meter/getErrorLogs", filter);
    },
    // getErrorLogs() {
    //   try {
    //     this.isLoading = true;
    //     this.axios
    //       .get(`/admin/meters/${this.meterId}/error-logs?type=${this.filter.type}`)
    //       .then((res) => {
    //         this.errorLogs = res.data.data;
    //         this.errorLog = _.head(this.errorLogs);
    //         this.isLoading = false;
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //         this.isLoading = false;
    //       });
    //   } catch (err) {
    //     console.log(err);
    //     this.isLoading = false;
    //   }
    // },
    dateFormat(date) {
      return moment(date).format("Do MMM, YYYY h:mm a");
    },
    showRawData(log) {
      this.selectedLog = log;
      this.show = true;
    },
    closeRawDataModal() {
      this.show = false;
    },
    updateStatus(error, status) {
      this.$store.dispatch("Settings/loading", true);
      this.$store
        .dispatch("Meter/updateMeterErrorStatus", {
          errorId: error.id,
          status: status,
        })
        .then(
          () => {
            this.$store.dispatch("Settings/loading", false);
            this.$toastr.s(
              "Meter Error status successfully updated.",
              "Success!"
            );
          },
          (err) => {
            // console.log(err.response);
            this.$store.dispatch("Settings/loading", false);
            this.$toastr.e(err.response.data.message, "Failed!");
          }
        );
    },
  },
  watch: {
    $route() {
      this.filter.meterId = this.$route.params.id;
      this.$store.dispatch("Meter/getErrorLogs", this.filter);
    },
  },
  mounted() {
    this.filter.meterId = this.$route.params.id;
    this.$store.dispatch("Meter/getErrorLogs", this.filter);
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
.badge {
  padding: 5px 7px;
}
.update-status-btn-width {
  width: 100%;
}
.hide-footer >>> footer {
  display: none;
}
</style>

<style scoped>
.custom-button {
  color: #000;
  padding: 10px 8px 10px 15px;
  font-size: 15px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.4);
  line-height: 1;
}
.cdropdown {
  display: inline-block;
}

.card {
  border: none;
  padding:15px 15px 0;
}
tbody:before {
  content: "@";
  display: block;
  line-height: 30px;
  text-indent: -99999px;
}
</style>

<style lang="scss" scoped>
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }
    tbody {
      font-size: 13px;
      tr {
        color: #000;
        td {
          padding: 0.75rem 0 0.75rem 0.75rem;
          button {
            font-size: 13px;
          }
        }
      }
    }
  }
}
</style>
